.wavybody{
	position: fixed;
	top:0; bottom:0; left:0; right:0; 
	background-color:rgba(0,0,0,.8);
	display:flex;
	justify-content: center;
	align-items: center;
	z-index: 10;
}
.wavy{
	font-family: Open Sans;
	position:relative;
	-webkit-box-reflect: below -12px linear-gradient( transparent, rgba(0,0,0,.2));
}
.wavy span{
	position: relative;
	display: inline-block;
	color: #fff;
	font-size: 4em;
	/*text-transform: uppercase;*/
	animation: animate 1s ease-in-out infinite;
	animation-delay: calc(0.1s * var(--i));
}
@keyframes animate{
	0%
	{
		transform: translateY(0px);
	}
	20%
	{
		transform: translateY(-20px);
	}
	40%,100%
	{
		transform: translateY(0px);
	}
}