.tracking_item_1{
    background-color:#FFFFFF0D;
}
.tracking_item_1:hover{
    background-color:#FFFFFF1D;
}
.tracking_item_2{
    background-color:transparent;
}
.tracking_item_2:hover{
    background-color:#FFFFFF1D;
}
