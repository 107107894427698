.my-selected:not([disabled]) { 
    font-weight: bold; 
    border: 2px solid currentColor;
    border-radius: 99px;
    background-color: rgb(0, 32, 42);
    color: rgb(95, 197, 255);

  }
.my-selected:hover:not([disabled]) { 
  border-color: rgb(0, 32, 42);
  color: rgb(95, 197, 255)
} 
.my-today { 
  font-weight: bold;
  font-size: 140%; 
  color: red;
}
.rdp-button_reset{
    padding: 12px 16px;
}
.rdp {
    --rdp-cell-size: 40px; /* Size of the day cells. */
    --rdp-caption-font-size: 18px; /* Font size for the caption labels. */
    
    --rdp-accent-color: rgb(95, 197, 255); /* HOVER: Accent color for the background of selected days. */
    --rdp-background-color: rgb(0, 126, 165); /* Background color for the hovered/focused elements. */

    --rdp-outline: 2px solid var(--rdp-accent-color); /* Outline border for focused elements */
    --rdp-outline-selected: 3px solid var(--rdp-accent-color); /* Outline border for focused _and_ selected elements */
    --rdp-selected-color: rgb(95, 197, 255); /* ???Color of selected day text */
  
    margin: 0;
}

.rdp-button_reset {
    appearance: none;
    position: relative;
    margin: 0;
    padding: 0;
    cursor: default;
    color: white;
    background: none;
    font: inherit;
    flex: 1;
    -moz-appearance: none;
    -webkit-appearance: none;
    width: 30px;
    height: 30px;
}

.rdp-button_reset:focus-visible {
  /* Make sure to reset outline only when :focus-visible is supported */
  outline: none;
}

.rdp-button {
  border: 2px solid transparent;
}

.rdp-button[disabled]:not(.rdp-day_selected) {
  opacity: 0.25;
}

.rdp-button:not([disabled]) {
  cursor: pointer;
}

/* HOVER STYLE */
.rdp-button:hover:not([disabled]):not(.rdp-day_selected) {
  background-color: rgb(0, 72, 94);
  color:var(--rdp-accent-color);
  border-radius: 99px;
  border-color: var(--rdp-accent-color);
}
.rdp-nav{
    display: inline-block;    
}
.rdp-nav > button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
}
.rdp-caption{
    display: flex;
    justify-content: space-between;
}
/* 
label */
.rdp-caption_label {
    position: relative;
    z-index: 1;
    display: inline-flex;
    align-items: center;
    margin: 0;
    padding: 0 0.25em;
    white-space: nowrap;
    color: white;
    border: 0;
    border: 2px solid transparent;
    font-family: inherit;
    font-size: var(--rdp-caption-font-size);
    font-weight: bold;
  }

  .prevent-select {
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */
  }

  .rdp-day_selected{
    background-color: rgb(0, 32, 42) !important;
    color: rgb(95, 197, 255) !important;
    border-color: rgb(95, 197, 255) !important;
  }