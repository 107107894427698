.cluster-circle{
    background: radial-gradient(#33333300, #33333300 38%, #0095D420 50%, #E0EEFF30 67%, #E0EEFF 68%, #E0EEFF 70%, #E0EEFF00 55%);
}
.battery-circle{
    background: radial-gradient(#33333300, #33333300 38%, #0095D420 50%, #E0EEFF80 67%, #E0EEFF80 68%, #E0EEFF80 70%, #E0EEFF00 55%);
}


.progress {
    position: absolute;
}

.progress-circle {
  transform: rotate(-90deg);
}

.progress-circle-back {
	fill: none; 
	stroke: #D2D2D2;
	stroke-width:10px;
}

.progress-circle-prog {
	fill: none; 
	stroke-width: 3px;     
	stroke-dashoffset: 0px;
    transition: stroke-dasharray 0.7s linear 0s;
}

.progress-text {
	width: 100%;
	position: absolute;
	top: 60px;
	text-align: center;
	font-size: 2em;
}