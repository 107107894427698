.diamond-gradient {
    background:
     linear-gradient(to bottom right,#FF179400 0%, #ffffff00 25.23%, #ffffff60 34.56%, #FFF 38%,#FFF 39%, #FFFFFF00 39%, #00C2FF00 46%, #00C2FF 49%,#00C2FF 50%, #39ED9400 50%) bottom right,
     linear-gradient(to bottom left, #FF179400 0%, #ffffff00 25.23%, #ffffff60 34.56%, #FFF 38%,#FFF 39%, #FFFFFF00 39%, #00C2FF00 46%, #00C2FF 49%,#00C2FF 50%, #39ED9400 50%) bottom left,
     linear-gradient(to top left,    #FF179400 0%, #ffffff00 25.23%, #ffffff60 34.56%, #FFF 38%,#FFF 39%, #FFFFFF00 39%, #00C2FF00 46%, #00C2FF 49%,#00C2FF 50%, #39ED9400 50%) top left,
     linear-gradient(to top right,   #FF179400 0%, #ffffff00 25.23%, #ffffff60 34.56%, #FFF 38%,#FFF 39%, #FFFFFF00 39%, #00C2FF00 46%, #00C2FF 49%,#00C2FF 50%, #39ED9400 50%) top right;
     
    background-size:50% 50%;
    background-repeat:no-repeat;
    animation: diamond-animation 8s infinite;
}
@keyframes diamond-animation {
    0% {
        width:80px;
        height:80px;
        transform: translate(-40px, -40px);
    }
    50% {
        width:86px;
        height:86px;
        transform: translate(-43px, -43px);
    }
    100% {
        width:80px;
        height:80px;
        transform: translate(-40px, -40px);
    }
  }

  .circle-grandient {
    background: radial-gradient(#33333300, #33333300 38%, #0095D420 50%, #E0EEFF30 67%, #E0EEFF 68%, #E0EEFF 70%, #E0EEFF00 55%);
    transition: 1s all ease;
    animation: circle-animation 5s infinite;
  }
  @keyframes circle-animation {
    0% {
        width:60px;
        height:60px;
        transform: translate(-30px, -30px);
    }
    50% {
        width:68px;
        height:68px;
        transform: translate(-34px, -34px);
    }
    100% {
        width:60px;
        height:60px;
        transform: translate(-30px, -30px);
    }
  }